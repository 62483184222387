@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@font-face {
  font-family: 'NeutralFace';
  src: local('NeutralFace'), url(./assets/fonts/NeutralFace.otf) format('opentype');
}

@font-face {
    font-family: 'NeutralFace';
    font-weight: 900;
    src: local('NeutralFace'), url(./assets/fonts/NeutralFace-Bold.otf) format('opentype');
}

html {
    font-size: 0.90rem;
}
th {
    color: #5b7dbc;
}
a, p, small, label, input {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #5b7dbc;
}
h1, h2, h3, h4, h5 {
    font-family: 'NeutralFace', Arial, serif;
    color: #5b7dbc;
}

a.disabled {
    cursor: not-allowed!important;
    background-color: #e8f4b1;
}
.bg-success {
    background-color: #29d786!important;
}
.bg-danger {
    background-color: #fe466f!important;
}
.btn {
    border-radius: 0rem;
}
.acco-header {
    color: #5b7dbc;
    font-weight: bolder;
    font-size: 1.5rem;
}
.text-bigger {
    font-size: 1.3rem!important;
}

.App {
  text-align: center;
}

.App-logo {
  width: 100px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nuvala-hero {
    background-size: cover;
    height: 100vh;
    z-index: -10;
}

.nuvala-scroll-down {
    position: absolute;
    width: 300px;
    text-align: center;
    font-size: 18px;
    left: calc(50% - 150px);
    bottom: 35vw;
}

.img-carousel {
    object-fit: cover;
    height: 100vh;
}

.nuvala-navbar {
    position: relative;
    z-index: 10;
    background-color: #ffffff;
    border: 2px solid #D7FF1E;
    border-radius: 20px;
}

.text-yellow {
    color: #D7FF1E!important;
}
.text-border-blue {
    text-shadow: 1px 0 #5b7dbc, -1px 0 #5b7dbc, 0 1px #5b7dbc, 0 -1px #5b7dbc, 1px 1px #5b7dbc, -1px -1px #5b7dbc, 1px -1px #5b7dbc, -1px 1px #5b7dbc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-mapbox-ac-menu {
  margin-top: 0.2rem !important;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-switch {
    padding-left: 2.25rem;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #c9f93d;
    background-color: #b2d1ea;
}
.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
}
.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}
.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}
.dropdown-menu {
    right: 0px;
    top: 30px;
}

.form-control:focus {
    color: #517ec1;
}

.form-check-input:checked {
    background-color: #517ec1;
    border: 3px solid #e0ff58;
    outline: 1px solid #517ec1;
}

.form-check-input[type=checkbox] {
    border-radius: 0.75em;
}

.form-check-input:checked[type=checkbox] {
    background-image: none;
}

.form-check-label {
    line-height: 1.9rem;
    margin-left: 0.7rem;
}
.form-check-input {
    outline: 1px solid #517ec1;
    background-color: #e0ff58;
    border: none;
    width: 1.5em;
    height: 1.5em;
}

.label-checkbox {
    margin-left: 15px;
    font-size: 14px;
    line-height: 1.7rem;
}

.image_box {
    overflow: hidden;
    width: 150px;
    height: 150px;
    outline: rgb(202, 249, 61) solid 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image_box img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}

.start_border {
    border-left: 1px solid gray;
}
.offmonth_cell {
    background-color: #CCFF00 !important;
}
.weekend_cell {
    color: #1a0475;
}
.today_cell {
    background-color: #efefef !important;
}
.calendar_cell {
    width: 100%;
}
.calendar_cell_reserved {
    background-color: #e0ff58;
}

.calendar_cell > small {
    font-size: .775em;
}

.table>:not(caption)>*>* {
    padding: 0.5rem 0.1rem;
}

.accommodation_cell {
    width: 400%;
    font-size: .775em;
    font-weight: bold;
}
.calendar_cell > input {
    width: 100%;
    border: 0px;
    text-align: center;
    font-size: 12px;
    background-color: inherit;
}
.spinner-grow {
    width: 1rem;
    height: 1rem;
}

th:first-child, td:first-child {
    position:sticky;
    left:0px;
    background-color: #517EC1;
    color: white;
}
.table>:not(:first-child) {
    border-top: 2px solid #517EC1;
}

#map {
    border-top: 2px solid #bbff1d;
    position: relative;
}

#map > .mapboxgl-map {
    height: 100vh !important;
}

.nuvola_popup_loader {
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
    margin: 40vh auto 0px;
}

.nuvola_popup {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999;
    height: 80vh;
    margin: 10vh auto 0px;
    display: none;
}

.nuvola_popup.active {
    display: block;
}

#location_input {
    position: absolute;
    width: 80%;
    max-width: 400px;
    left: 0;
    right: 0;
    margin: 30px auto;
    z-index: 140;
}

.btn-yellow {
    background-color: #e0ff58;
}

.filter-box {
    position: absolute;
    height: auto!important;
    min-width: 350px;
    width: 26%;
    bottom: 20px;
    left: 15px;
    z-index: 200;
}

.flipcard {
    perspective: 800;
    -moz-perspective: 800;
    -webkit-perspective: 800;
    transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
}

.flipcard.flipped {
    transform: rotatey(-180deg);
    -moz-transform: rotatey(-180deg);
    -webkit-transform: rotatey(-180deg);
}
.flipcard .face {
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.flipcard .front {
    display: block;
}
.flipcard.flipped .front {
    display:none;
}
.flipcard .back {
    transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    display:none;
}
.flipcard.flipped .back {
    display:block;
}

footer {
    z-index: 10;
    outline: 2px solid #e0ff58;
    background-color: #5b7dbc;
}

.logo-footer {
    margin-top: 20px;
}

.text-white {
    color: white!important;
}

.facility_card {
    max-width: 300px;
}

.btn-nuvala {
    color: #6c757d;
    border: 2px solid #5b7dbc;
    background-color: #e0ff58;
}

.input-group-text {
    background-color: white;
    border: 1px solid #5380c2;
    border-radius: 0rem!important;
}

.input-group-sm>.btn, .input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text {
    border-radius: 0rem!important;
}

.nuvala-hr {
    border: none;
    height: 3px!important;
    background-color: rgb(81 126 193);
    margin-top: 0px;
    margin-bottom: 1.25rem;
}

.alert-nuvala {
    color: #141619;
    background-color: #e0ff58;
    border-color: #afdb06;
}

.toast {
    position: fixed;
    right: 40px;
    top: 90px;
    z-index: 9999;
}

.toast-header {
    padding: 0.5rem 0.15rem;
}

.text-nuvala-blue {
    color: #5b7dbc;
}
.text-nuvala-gray {
    color: #898989;
}
.accom-card-header {
    max-height: 200px;
    position: relative;
}

.acc-switch {
    position: absolute;
    bottom: 16px;
    left: 16px;
}

.await-approval {
    border-radius: 1rem;
    border: 2px solid #517ec1;
    padding: 10px;
    margin-bottom: 20px;
}

.fill {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
}
.fill img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
}
.datepicker__navigation-icon::before {
    border-color: #517ec1;
}
.react-datepicker__header {
    background-color: #ffffff!important;
    border-bottom: none!important;
}
.react-datepicker__navigation {
    top: 10px !important;
}

.react-datepicker {
    font-family: inherit!important;
    border: none!important;
}

.react-datepicker__day--in-range {
    background-color: #e0ff58!important;
    color: #020202!important;
}

.react-datepicker__day--today {
    background-color: white!important;
    color: #020202!important;
    border-radius: 0.3rem;
    border: 2px solid #e0ff58;
}

.datepicker__navigation-icon::before {
    border-color: #517ec1;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #517ec1!important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #517ec1!important;
}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
    cursor: default;
    color: #ccc!important;
}

.price-detail-box {
    background-color: #ffffff;
    border-radius: 0rem;
    border: 2px solid #517ec1;
}

.nuvala-modal {
    background-color: #ffffff;
    border: 2px solid #517ec1;
    border-radius: 20px 20px 0px;
    padding: 2rem;
    position: relative;
    z-index: 9999;
}

.nuvala-yellow-modal-header {
    padding: 0.9rem 2rem 0.9rem;
    background-color: #517ec1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 2px solid #e0ff58;
}
.nuvala-yellow-modal-body {
    background-color: #ffffff;
    padding: 0rem 1rem 3rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0;
    border: 2px solid #517ec1;
    border-top: none;
    position: relative;
}

.logo-home {
    position: absolute;
    width: 60%;
    max-width: 400px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, 0);
    z-index: 9999;
}
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  opacity: 1;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
  background: white;
  height: 0.5rem;
}

/******** Firefox ********/
input[type="range"]::-moz-range-track {
  background: white;
  height: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
    background-color: #e0ff58;
    border: 1px solid black;
}

input[type="range"]::-moz-range-thumb {
    background-color: #e0ff58;
    border: 1px solid black;
}

.close-top-right {
    position: absolute;
    top: 15px;
    right: 15px;
}
.close-bottom-right {
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.nuvala-arrow-right {
    padding: 0.25em 2em;
    background: transparent url('assets/images/arrow-right.svg') center/3em auto no-repeat;
}
.nuvala-arrow-left {
    padding: 0.25em 2em;
    background: transparent url('assets/images/arrow-left.svg') center/3em auto no-repeat;
}
.nuvala-arrow-down {
    padding: 0.75em 0.5em;
    background: transparent url('assets/images/arrow-down.svg') center/auto auto no-repeat;
}
.nuvala-arrow-up {
    padding: 0.75em 0.5em;
    background: transparent url('assets/images/arrow-up.svg') center/auto auto no-repeat;
}
.nuvala-user {
    padding: 0.75em 1em;
    background: transparent url('assets/images/user.svg') center/auto auto no-repeat;
}
.nuvala-user-cont {
    margin-top: 0.55rem!important;
}
.btn-icon {
    padding: 1.25rem 1.5rem;
}
.btn-square {
    padding: 0.375rem 0.375rem;
    border: 2px solid #000000;
    background-color: #e0ff58;
}

.form-control {
    border: 1px solid #5b7dbc;
    border-radius: 9px;
    color: #5b7dbc!important;
    font-weight: 500;
    padding: 0.32rem 0.75rem;
}

.form-control-filters {
    border: 2px solid #1ea1f1;
    border-radius: 10px 0px 0px 10px !important;
    min-height: 38px;
    font-weight: 400;
}
.form-control-filters-center {
    border: 2px solid #1ea1f1;
    border-radius: 0px 0px 0px 0px !important;
    min-height: 38px;
    font-weight: 400;
}
.form-control-filters-right {
    border: 2px solid #1ea1f1;
    border-radius: 0px 10px 10px 0px !important;
    min-height: 38px;
    font-weight: 400;
}

.form-select {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    border: 1px solid #5b7dbc;
    border-radius: 10px 0px 0px 10px;
    padding-left: 12px;
    color: #5b7dbc;
}
label {
    color: #517ec1;
}

.accordion-header {
    border: 1px solid #5b7dbc;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.accordion-button {
    background-color: transparent;
}
.accordion-collapse {
    border: 1px solid #5b7dbc !important;
    border-top: none !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 0rem !important;
}
.accordion-button {
    color: #517ec1;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #000;
    font-weight: 700;
    background-color: #e0ff58;
}

.dropdown-menu {
    background-color: #ffffff;
    border: 2px solid #D7FF1E;
    border-radius: 10px;
}
.dropdown-item:focus, .dropdown-item:hover {
    font-weight: 600;
    color: #517ec1;
    background-color: #ffffff;
}

.nav-link:focus, nav-link:hover {
    font-weight: 600;
}

.nav-link {
    padding: 0.6rem 1rem;
}

.card {
    border: 1px solid #517ec1;
    border-radius: 0rem;
}

.calendar-wrapper {
    background-color: white;
    border: 2px solid #517ec1;
}

.chat-container {
    border: 1px solid #517ec1;
}
.chat-head {
    border-bottom: 1px solid #517ec1;
}
.chat-body {
    height: 60vh;
}
.chat-input {
    width: 100%;
    border-top: 1px solid #517ec1;
}
.btn-outline-nuvala {
    color: #517ec1;
    border-color: #517ec1;
    background-color: white;
}

.buddy-card {
    border: 1px solid #1da1f2;
    border-radius: 5px;
    padding: 10px;
}

.buddy-card > input {
    border: 1px solid #1da1f2;
    background-color: inherit;
    border-radius: 4px;
}

.marquee {
    margin: -80px auto 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    font-size: 3.5rem;
    color: #BBFF1E;
    z-index: 9999;
}

.marquee-inverted span {
    background-color: #e0ff58!important;
    color: #517ec1;
}

.marquee-slow span {
    animation: marquee 70s linear infinite!important;
}

.marquee span {
    z-index: 9999;
    font-family: NeutralFace;
    font-weight: 100;
    display: inline-block;
    animation: marquee 7s linear infinite;
    background-color: #517ec191;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.groups-vertical-text {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    /* also accepts left, right, top, bottom coordinates; not required, but a good idea for styling */
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;

    /* Should be unset in IE9+ I think. */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    color: #5b7dbc;
    white-space: nowrap;
    margin-top: 70px;
    font-weight: 600;
}

.select__control {
    border-radius: 10px 0px 0px 10px !important;
    border: 2px solid #1da1f2 !important;
}

.select__indicator-separator {
    border-left: 2px solid #1da1f2 !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}
.select__indicator {
    background-color: #BBFF1E !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    color: #1da1f2 !important;
    height: 100%;
}

.select__clear-indicator {
    border-left: 2px solid #1da1f2 !important;
}
.select__multi-value {
    background-color: #A9D1EB !important;
    border-radius: 0px !important;
    font-weight: 600 !important;
}
.select__multi-value__label {
    color: white !important;
}
.select__multi-value__remove {
    color: white !important;
    border-left: 1px solid white !important;
    border-radius: 0px !important;
}

.arrow-scroll {
    font-size: 3vw;
    color: #BBFF1E;
    animation: upNdown 0.8s infinite linear;
    font-family: 'NeutralFace';
}

.nuvala-yellow-modal-button {
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 0;
    padding: 6px 10px;
    border-top: 2px solid #517ec1;
    border-left: 2px solid #517ec1;
}

@keyframes upNdown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(12px);
    }
}

.small, small {
    font-size: 0.750rem;
}

.nuvala-gray-modal-header {
    padding: 1rem 2rem;
    height: 50px;
    background-color: #d3d3d3;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: 2px solid #9c6dff;
}
.nuvala-gray-modal-body {
    background-color: #e0ff58;
    padding: 1rem 2rem;
    border: 2px solid #517ec1;
    border-top: none;
    border-bottom: none;
}
.nuvala-gray-modal-footer {
    background-color: #e0ff58;
    padding: 1rem 0rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 2px solid #517ec1;
}

.modal-dialog {
    margin: 0.5rem auto;
}

.modal-lightbox-content {
    background-color: transparent;
    border: none;
}
.modal-lightbox-image {
    border: 2px solid #D7FF1E;
    border-radius: 10px;
    position: relative;
}
.modal-lightbox-image > .img-fluid {
    border-radius: 10px;
}
.lightbox-arrow {
    padding: 10px;
    border-radius: 10px;
    background-color: #D7FF1E;
    border: 2px solid #517ec1;
}
.btn-nuvala-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 2px solid #517ec1;
    border-left: 2px solid #517ec1;
    padding: 6px 12px;
    text-decoration: none;
}

.btn-nuvala-bottom-right-group {
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 2px solid #517ec1;
    border-left: 2px solid #517ec1;
}

#map-container {
    position: relative;
}

.map-ui {
    position: absolute;
    bottom: 20px;
    right: 15px;
    z-index: 130;
}

.video {
    height: 0px;
    padding-bottom: 56.2%;
    position: relative;
}

@media (max-width: 576px) {
    .map-ui  { bottom: calc(50% - 50px); }
}

.map-ui.list-group {
    border-radius: 1.5rem;
    border: 2px solid #517ec1;
}

.map-ui > .list-group-item {
    background-color: #fff;
    border-top: 2px solid #517ec1;
    color: #517ec1;
    font-family: Roboto,serif;
    font-size: 2rem;
    padding: 0.4rem 1.5rem;
}

.map-ui > .list-group-item:first-child {
    border-top: none;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 900px;
    }
}

.nuvala-list-group-item {
    background-color: #e0ff58;
    border: 2px solid #517ec1;
    border-radius: 10px!important;
}

.img-admin-thumbnail {
    width: 100%;
    max-width: 200px;
}

.square-img {
    object-fit: cover;
    width:230px;
    aspect-ratio: 1/1;
}

.rounded-15 {
    border-radius: 15px!important;
}

.nav-pills .nav-link {
    border-radius: 15px;
}

.toast-header {
    padding: 0.5rem 0.15rem;
}
.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}
.toast-header {
    color: #e0ff58;
    background-color: #517ec1;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 2px solid #e0ff58;
}

.toast {
    background-color: #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.toast-body {
    border-bottom: 2px solid #517ec1;
    border-left: 2px solid #517ec1;
    border-bottom-left-radius: 15px;
    border-right: 2px solid #517ec1;
}

.manifesto-block-1 {
    margin-top: 75%;
}

.manifesto-block-2 {
    margin-top: 3%;
}

.manifesto-block-3 {
    margin-top: 3%;
}

thead > tr:first-child {
    border-bottom: 2px solid #e0ff58;
}

.nuvala-red-bg {
    background-color: #ffc7c7;
}
.nuvala-green-bg {
    background-color: #a0ffd6;
}

a, li, .nuvala-yellow-modal-button {
    cursor: pointer!important;
}

.react-datepicker__day--outside-month {
    opacity: 0.5;
}

.react-datepicker__day {
    width: 2.2rem!important;
    line-height: 2rem!important;
}
.react-datepicker__month-container {
    float: none!important;
}
.lightbox-wrap {
    flex-wrap: wrap;
}
.payment-customer-alert {
    border: 1px solid #517ec1;
    padding: 15px;
    background: #e1ff58;
    margin: 10px 0;
}

.nuvala-modal-sm {
    margin-top: 50%;
}

.preserve-newlines {
    white-space: pre-line;
}

.first-block-manifesto {
    margin-top: 60%;
}

.second-block-manifesto {
    margin-top: 60%;
}

@media (min-width: 450px) {
    .nuvala-scroll-down {
        bottom: 35vw;
    }
}

@media (min-width: 768px) {
    .lightbox-wrap {
        flex-wrap: nowrap;
    }
    .nuvala-scroll-down {
        bottom: 30vw;
    }
    .manifesto-block-1 {
        margin-top: 40vh;
    }
    .manifesto-block-2 {
        margin-top: 35vh;
    }
    .manifesto-block-3 {
        margin-top: 5vh;
    }
    .logo-footer {
        margin-top: 10%;
    }
    .b-nuvala-right {
        border-right: 2px solid #517ec1;
    }
    .b-nuvala-left {
        border-left: 2px solid #517ec1;
    }
    .b-nuvala-bottom {
        border-bottom: 2px solid #517ec1;
    }
    .b-nuvala-top {
        border-top: 2px solid #517ec1;
    }
    .react-datepicker__month-container {
        float: left!important;
    }
    .nuvala-modal-sm {
        margin-top: 1%;
    }
    .first-block-manifesto {
        margin-top: 10%;
    }
    .second-block-manifesto {
        margin-top: 0%;
    }
}

@media (min-width: 992px) {
    .nuvala-scroll-down {
        width: 600px;
        left: calc(50% - 300px);
    }
    .nuvala-scroll-down {
        bottom: 26vw;
    }
}

@media (min-width: 1200px) {
    .nuvala-scroll-down {
        bottom: 20vw;
    }
}